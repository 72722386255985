import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import { YMaps, Map, Placemark } from 'react-yandex-maps'
import Layout from '../components/layout'

const Div = styled.div`
  .hero {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 61.8vh;
    max-height: 600px;

    text-align: center;
  }

  h1 {
    font-family: 'GT Walsheim Pro Bold';
  }

  img {
    width: auto;
    max-width: 100%;
  }

  .heroImage {
    position: relative;
    height: 600px;
    width: 600px;
    max-height: 450px;
  }

  @media (max-width: 600px) {
    .heroImage {
      width: 100%;
    }
  }
`
const Body = styled.div`
  a {
    color: blue;
  }
`

const Iframe = styled.iframe`
  width: 100%;
  height: 540px;
`

const removeByIndex = (str, index) => {
  return str.slice(0, index) + str.slice(index + 1)
}

const y2lat = (y) => {
  return (2 * Math.atan(Math.exp(y / 6378137)) - Math.PI / 2) / (Math.PI / 180)
}

const x2lon = (x) => {
  return x / (Math.PI / 180.0) / 6378137.0
}

const AlushtaTemplate = (props) => {
  const post = props?.data?.contentfulAlushta
  const [internalGeo, setInternalGeo] = React.useState({})

  // React.useEffect(() => {
  //   if (post?.cadastralNumber) {
  //     const numberForFetch = removeByIndex(post.cadastralNumber, 6)

  //     fetch(`https://pkk.rosreestr.ru/api/features/1/${numberForFetch}`)
  //       .then((res) => res.json())
  //       .then((res) => {
  //         if(res.feature){
  //           const { center } = res.feature
  //           setInternalGeo({
  //               lat: y2lat(center.y),
  //               lon: x2lon(center.x),
  //             })
  //         }
  //       })
  //   }
  // }, [])

  return (
    <YMaps>
      <Layout location={props.location}>
        <Div>
          <Helmet title={post?.title} />
          <div className="wrapper">
            <h1 className="section-headline">{post?.title}</h1>
            <p
              style={{
                display: 'block',
              }}
            >
              {post?.city + ' ' + post?.address}
            </p>
            <p>Тип: {post?.type}</p>
            {/* {post?.cadastralNumber && (
              <p>Кадастровый номер: {post.cadastralNumber}</p>
            )} */}
            {/* <Iframe src={`https://egrp365.org/p_map/?kadnum=${post?.cadastralNumber}`}></Iframe> */}

            {post?.geo ? Object.keys(internalGeo).length === 0 ? (
              <Map
                width="100%"
                height="540px"
                defaultState={{
                  center: [post?.geo?.lat, post?.geo?.lon],
                  zoom: 16,
                }}
              >
                <Placemark geometry={[post?.geo?.lat, post?.geo?.lon]} />
              </Map>
            ) : (
              <Map
                width="100%"
                height="540px"
                defaultState={{
                  center: [internalGeo.lat, internalGeo.lon],
                  zoom: 16,
                }}
              >
                <Placemark geometry={[internalGeo.lat, internalGeo.lon]} />
              </Map>
            ): null}

            <Body
              dangerouslySetInnerHTML={{
                __html: post?.body?.childMarkdownRemark?.html,
              }}
            />
          </div>
        </Div>
      </Layout>
    </YMaps>
  )
}

export default AlushtaTemplate

export const pageQuery = graphql`
  query AlushtaQuery($slug: String!) {
    contentfulAlushta(slug: { eq: $slug }) {
        geo {
          lat
          lon
        }
        date
        city
        address
        slug
        title
        type
        body {
          childMarkdownRemark {
            html
          }
        }
      }
  }
`
